import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { v4 as uuidv4 } from "uuid"
import { useOnClickOutside } from "../../hooks"
import { RichText } from "prismic-reactjs"
import CustomLink from "../../utils/customLink"
import { Styled } from "./style"
import Button from "../Button"
import Icon from "../Icon"

const Form = ({ data, subject }) => {
  const ref = useRef()
  const [selectOpen, setSelectOpen] = useState(false)
  useOnClickOutside(ref, () => setSelectOpen(false))
  const [nameData, setNameData] = useState("")
  const [companyData, setCompanyData] = useState("")
  const [messageData, setMessageData] = useState("")
  const [messagePlaceholder, setMessagePlaceholder] = useState("")
  const [messageBodyData, setMessageBodyData] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const privacyStatement = data.privacy_statement?.raw || ""
  const buttonLabel = data.form_button?.text || "Send message"
  const emailRecipient = data.email_recipient?.text
  let subjectOptions = data.subject_options || [
    { option_label: { text: "General inquiry" } },
    { option_label: { text: "Question regarding a product" } },
  ]
  const [selectedSubject, setSelectedSubject] = useState(
    subject || subjectOptions[0].option_label.text || "General inquiry"
  )

  useEffect(() => {
    subjectOptions.forEach(item => {
      if (item.option_label.text === selectedSubject) {
        setMessagePlaceholder(item.message_placeholder?.text || "")
      }
    })
  })

  const initialData = {
    nameInput: {
      name: "name",
      label: data.name_input_label.text,
      placeholder: data.name_input_placeholder.text,
    },
    companyInput: {
      name: "company",
      label: data.company_input_label.text,
      placeholder: data.company_input_label.text,
    },
    subjectInput: {
      name: "subject",
      label: data.subject_input_label.text,
    },
    messageInput: {
      name: "message",
      label: data.message_input_label.text,
      placeholder: messagePlaceholder || data.message_input_placeholder.text,
    },
  }
  const [formData, setFormData] = useState({
    [initialData.nameInput.name]: nameData,
    [initialData.companyInput.name]: companyData,
    [initialData.subjectInput.name]: selectedSubject,
    [initialData.messageInput.name]: messageData,
  })

  if (subject && !subjectOptions.some(el => el.option_label.text === subject))
    subjectOptions = [...subjectOptions, { option_label: { text: subject } }]

  const handleSubmit = e => {
    e.preventDefault()
    Object.values(formData).some(el => el === null || el === "")
      ? setErrorMessage(
          data.error_message?.text || "Please fill out all fields."
        )
      : (window.location.href = `mailto:${emailRecipient}?subject=${selectedSubject}&body=${messageBodyData}`)
  }

  const handleMessageBoxClick = () => {
    if (!messageData) setMessageData(messagePlaceholder)
  }

  const Label = data => (
    <Styled.Label htmlFor={data.name}>{data.label}</Styled.Label>
  )

  useEffect(() => {
    !Object.values(formData).some(
      element => element === null || element === ""
    ) && setErrorMessage("")
    setMessageData(messageData.replace(/\r\n|\r|\n/g, "%0D%0A"))
    setFormData({
      [initialData.nameInput.name]: nameData,
      [initialData.companyInput.name]: companyData,
      [initialData.subjectInput.name]: selectedSubject,
      [initialData.messageInput.name]: messageData,
    })
    setMessageBodyData(
      `${initialData.nameInput.label}: ${nameData}${escape("\n\n")}${
        initialData.companyInput.label
      }: ${companyData}${escape("\n\n")}${
        initialData.messageInput.label
      }:${escape("\n\n")}${messageData}`
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameData, companyData, selectedSubject, messageData])

  if (!data) return null

  return (
    <Styled.Section>
      <form method="post" name="contact" onSubmit={handleSubmit}>
        <Styled.Wrapper>
          <input type="hidden" name="form-name" value="contact" />
          <Styled.InputWrapper>
            {Label(initialData.nameInput)}
            <input
              type="text"
              name={initialData.nameInput.name}
              id={initialData.nameInput.name}
              placeholder={initialData.nameInput.placeholder}
              className={errorMessage && !nameData ? "error" : ""}
              onChange={e => setNameData(e.target.value)}
            />
          </Styled.InputWrapper>
          <Styled.InputWrapper>
            {Label(initialData.companyInput)}
            <input
              type="text"
              name={initialData.companyInput.name}
              id={initialData.companyInput.name}
              placeholder={initialData.companyInput.placeholder}
              className={errorMessage && !companyData ? "error" : ""}
              onChange={e => setCompanyData(e.target.value)}
            />
          </Styled.InputWrapper>
          <Styled.InputWrapper className="full-width" ref={ref}>
            {Label(initialData.subjectInput)}
            <Styled.Select
              type="button"
              className={`select-subject${selectOpen ? " open" : ""}`}
              onClick={() => setSelectOpen(!selectOpen)}
            >
              {selectedSubject}
              <Icon name="chevron down" />
            </Styled.Select>
            {selectOpen && (
              <Styled.SelectOptions>
                {subjectOptions.map(option => (
                  <li
                    onClick={e => {
                      setSelectedSubject(e.target.innerHTML)
                      setSelectOpen(false)
                    }}
                    role="presentation"
                    key={uuidv4()}
                  >
                    {option.option_label.text}
                  </li>
                ))}
              </Styled.SelectOptions>
            )}
          </Styled.InputWrapper>
          <Styled.InputWrapper className="full-width">
            {Label(initialData.messageInput)}
            <textarea
              name={initialData.messageInput.name}
              id={initialData.messageInput.name}
              placeholder={initialData.messageInput.placeholder}
              value={messageData}
              rows="7"
              multi="true"
              className={errorMessage && !messageData ? "error" : ""}
              onChange={e => setMessageData(e.target.value)}
              onClick={() => handleMessageBoxClick()}
            />
          </Styled.InputWrapper>
          {privacyStatement && (
            <Styled.PrivacyStatement>
              <RichText
                render={privacyStatement || []}
                serializeHyperlink={CustomLink}
              />
            </Styled.PrivacyStatement>
          )}
          <Button
            type="submit"
            link={{}}
            label={buttonLabel}
            className="button"
          />
          {errorMessage && (
            <Styled.ErrorMessage className="error-message">
              {errorMessage}
            </Styled.ErrorMessage>
          )}
        </Styled.Wrapper>
      </form>
    </Styled.Section>
  )
}

Form.propTypes = {
  data: PropTypes.object.isRequired,
  subject: PropTypes.string,
}

export default Form
