import styled from "styled-components"
import {
  boxShadow,
  borderRadius,
  breakpoint,
  elementSize,
  font,
  spacing,
  transition,
} from "../../styles/variables"

export const Styled = {
  Section: styled.section`
    max-width: ${elementSize.contentWidth.text};
    margin: ${spacing.xxl} auto;
  `,
  Wrapper: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .button,
    .error-message {
      margin-top: ${spacing.md};

      input {
        width: 100%;
      }
    }
  `,
  Label: styled.label`
    display: block;
    margin-bottom: ${spacing.xs};
    user-select: none;
  `,
  InputWrapper: styled.div`
    position: relative;
    flex-basis: 100%;
    max-width: 100%;
    padding: ${spacing.xs} 0;

    @media screen and (min-width: ${breakpoint.md}) {
      max-width: 49%;
    }

    &.full-width {
      @media screen and (min-width: ${breakpoint.md}) {
        max-width: 100%;
      }
    }

    input,
    textarea,
    .select-subject {
      width: 100%;
      min-height: 62px;
      padding: ${spacing.xs};
      font: inherit;
      line-height: ${font.lineHeight.sm};
      color: ${props => props.theme.text};
      resize: none;
      background-color: ${props => props.theme.gray1};
      border: none;
      border-radius: ${borderRadius.sm};
      outline: none;

      &.error {
        border-radius: ${borderRadius.sm};
        outline: none;
        box-shadow: 0 0 0 0.08em ${props => props.theme.error};
      }

      &:placeholder {
        color: ${props => props.theme.gray3};
      }
    }

    select:hover {
      cursor: pointer;
    }
  `,
  Select: styled.button`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      cursor: pointer;
    }

    &.open {
      svg {
        transform: rotate(-180deg);
      }
    }

    svg {
      transition: ${transition.medium};

      path {
        fill: ${props => props.theme.text};
      }
    }
  `,
  SelectOptions: styled.ul`
    position: absolute;
    z-index: 10;
    top: 100px;
    left: 0;
    width: 100%;
    margin-bottom: 0;
    padding-left: 0;
    list-style-type: none;
    background-color: ${props => props.theme.backgroundLight};
    border-radius: ${borderRadius.sm};
    box-shadow: ${boxShadow};

    > li {
      cursor: pointer;
      padding: ${spacing.xs};
      border-radius: ${borderRadius.sm};
      transition: ${transition.fast};

      &:hover {
        background-color: ${props => props.theme.backgroundLightHover};
      }
    }
  `,
  ErrorMessage: styled.span`
    padding: ${spacing.xxs};
    color: ${props => props.theme.error};
  `,
  PrivacyStatement: styled.div`
    width: 100%;
    color: ${props => props.theme.text};
  `,
}
