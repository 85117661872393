import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Section from "../components/Section"
import Hero from "../components/Hero"
import Form from "../components/Form"

const ContactPage = ({ data, location }) => {
  if (!data) return null

  const pageData = data.prismicContact || {}
  const { lang, type, url } = pageData || {}
  const alternateLanguages = pageData.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  return (
    <Layout activeDoc={activeDoc}>
      <Seo
        title={pageData.data.title.text}
        description={pageData.data.description.text}
      />
      <Hero data={pageData.data} />
      <Section data={pageData.data} />
      <Form data={pageData.data} subject={location.state?.additionalArgument} />
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ContactPage

export const pageQuery = graphql`
  query ContactPageTemplateQuery($uid: String, $lang: String) {
    prismicContact(uid: { eq: $uid }, lang: { eq: $lang }) {
      alternate_languages {
        uid
        type
        lang
        url
      }
      lang
      url
      type
      data {
        title {
          text
          raw
        }
        description {
          text
        }
        hero_title {
          raw
        }
        hero_subtitle {
          raw
        }
        content {
          raw
        }
        email_recipient {
          text
        }
        name_input_label {
          text
        }
        name_input_placeholder {
          text
        }
        company_input_label {
          text
        }
        company_input_placeholder {
          text
        }
        message_input_label {
          text
        }
        message_input_placeholder {
          text
        }
        subject_input_label {
          text
        }
        subject_options {
          option_label {
            text
          }
          message_placeholder {
            text
          }
        }
        form_button {
          text
        }
        error_message {
          text
        }
        privacy_statement {
          raw
        }
      }
    }
  }
`
